@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&family=Noto+Serif:wght@300&family=Open+Sans:wght@300&family=Sometype+Mono:wght@700&display=swap");

p {
	font-family: "Lato", sans-serif;
	color: lavender;
	text-decoration: none;
}

h4 {
	color: gray !important;
}

.custom-card {
	padding : 2em;
	background-color: rgba(0, 0, 0, 0.5);
	text-align: center;
	border-radius: 8px;
}

.custom-card > h4 {
	font-family: "Lato", sans-serif;
    color: lavender !important;
    text-decoration: none;
}
