@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&family=Noto+Serif:wght@300&family=Open+Sans:wght@300&family=Sometype+Mono:wght@700&display=swap");

.d-flex {
	font-family: "Lato", sans-serif;
	color: lavender;
	text-decoration: none;
}

.li-style {
	font-family: "Lato", sans-serif;
	color: lavender;
	text-decoration: none;
}

p {
	color: lavender;
}
